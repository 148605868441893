import { Controller } from "stimulus"

export default class extends Controller {
    static targets = [ "dataTable", "instrumentTileTop", "nonCompliantTileTop", "recentActivityTileTop"];

    // allow access to child controllers: https://leastbad.com/stimulus-power-move
    connect() {
        this.element[this.identifier] = this;
    }

    loadInstrumentData(event) {
        this.loadDataTableFor("instrument", this.instrumentTileTopTarget, event);
    }

    loadNonCompliantData(event) {
        this.loadDataTableFor("non_compliant", this.nonCompliantTileTopTarget, event);
    }

    recentActivityData(event) {
        this.loadDataTableFor("recent_activity", this.recentActivityTileTopTarget, event);
    }

    close(event) {
        event.preventDefault();

        $(this.dataTableTarget).empty();
        this.clearTileTops();
    }

    get url() {
        return this.data.get("url")
    }

    get orgType() {
        return this.data.get("orgType")
    }

    get tileTops() {
        return [this.instrumentTileTopTarget, this.nonCompliantTileTopTarget, this.recentActivityTileTopTarget]
    }

//    private methods

    loadDataTableFor(tableType, tileTop, event) {
        event.preventDefault();

        const url = `${this.url}&data_table=${tableType}`;
        const $target = $(this.dataTableTarget);
        const that = this;

        $target.block(blockUIOptions());

        $target.load(url, () => {
            that.clearTileTops();
            $(tileTop).addClass('on');
            $target.unblock()
        })
    }

    clearTileTops() {
        $(this.tileTops).each( function () { $(this).removeClass('on') } )
    }
}
