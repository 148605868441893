
require('block-ui');

import {Controller} from "stimulus"

export default class extends Controller {

    showTab(event) {
        const $tabTarget = $($(event.target).attr("href"));

        if ($tabTarget.html().trim().length === 0) {
            $tabTarget.block(blockUIOptions());

            $tabTarget.load(this.url, function () {
                $tabTarget.unblock();
                setTimeout( () => { initializeTablesort() }, 100);
                window.dispatchEvent(new Event('orgTabLoaded'))
            })
        }
    }

    get url() {
        return this.data.get("url")
    }
}
