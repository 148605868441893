import { Controller } from "stimulus"

export default class extends Controller {
    static targets = [ "content"];

    load(event) {
        event.preventDefault();

        // TODO figure out why show.bs.collapse is not firing...this is the workaroud
        if ($(event.target).attr('aria-expanded') == "false") {

            const $target = $(this.contentTarget);

            $target.block(blockUIOptions());

            $target.load(this.url, () => {

                initializeChosen();

                if ($('.tile-chart').length) {
                    orgDashboardMgr.mySchoolsDashboard.renderCharts();
                }

                if ($('#districts .tile-chart').length) {
                    orgDashboardMgr.myDistrictsDashboard.renderCharts();
                }

                $target.unblock()
            })
        }

    }

    get url() {
        return this.data.get("url")
    }

}
