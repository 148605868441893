import { Controller } from "stimulus"

export default class extends Controller {
    static targets = [ "content" ]

    loadDetail(event) {
        event.preventDefault();

        // TODO figure out why show.bs.collapse is not firing...this is the workaroud
        if ($(event.target).attr('aria-expanded') == "false") {

            const instrumentId = $(event.target).data('instrumentId');
            const url = `${this.url}&org_type=${this.orgType}&data_table_detail=${this.template}&instrument_id=${instrumentId}`;
            const $target = $(this.contentTarget);

            $target.block(blockUIOptions());

            $target.load(url, () => { $target.unblock() })

        }

    }

    get url() {
        return this.parentController.url;
    }

    get orgType() {
        return this.parentController.orgType;
    }

    get template() {
        return this.data.get("template");
    }

    get parentController() {
        return this.element.closest('[data-controller~=monitor-dashboard-by-type]')['monitor-dashboard-by-type']
    }
}