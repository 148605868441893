import { Controller } from "stimulus"

export default class extends Controller {
    static targets = [ "orgTable", "assignToggle", "unassignToggle", "checkbox", "assignForm", "unassignForm", "modal" ];

    showAssigned(event) {
        const assignedUrl = `${this.url}&assignment_type=assigned`;

        this.toggle();
        this.loadTable(assignedUrl);
    }

    showUnassigned(event) {
        const unassignedUrl = `${this.url}&assignment_type=unassigned`;

        this.toggle();
        this.loadTable(unassignedUrl);
    }

    toggleCheckboxes(event) {
        $(this.checkboxTargets).prop("checked", $(event.target).prop('checked'));
    }

    assignOrgs(event) {
        event.preventDefault();

        if (this.verifyOrgSelected()) { this.postChangesTo(this.assignFormTarget) }
    }

    unassignOrgs(event) {
        event.preventDefault();

        if (this.verifyOrgSelected()) { this.postChangesTo(this.unassignFormTarget) }

    }

    showModal(event) {
        event.preventDefault();

        if (this.verifyOrgSelected()) {
            const modalEvent = new CustomEvent('showBulkEditModal', { 'detail': { orgByTypeController: this, organizationIds: this.selectedOrganizationIds(), modalMessage: this.modalMessage() } });

            window.dispatchEvent(modalEvent);
        }
    }

    updateTable(event) {
        $(this.orgTableTarget).html(event.detail.orgTableHTML);
    }

    get url() {
        return this.data.get("url");
    }

    get orgType() {
        return this.data.get("orgType");
    }

    get $parentContainer() {
        return $(this.orgTableTarget).parents(".tab-content").last();
    }

    get noOfOrganizationsSelected() {
        return this.organizationsSelected.length;
    }

    get organizationsSelected() {
        return this.checkboxTargets.filter( ({ checked }) => checked === true )
    }

//    private methods

    loadTable(assignedUrl) {
        const $target = $(this.orgTableTarget);
        const that = this;

        this.$parentContainer.block(blockUIOptions());

        $target.load(assignedUrl, function () {
            that.$parentContainer.unblock();
            setTimeout( () => { initializeTablesort() }, 100);
        })
    }

    toggle() {
        $(this.assignToggleTarget).toggleClass('active');
        $(this.unassignToggleTarget).toggleClass('active');
    }

    postChangesTo(form) {
        const formUrl = $(form).attr('action');
        const $target = $(this.orgTableTarget);
        const that = this;

        this.$parentContainer.block(blockUIOptions());

        $.post(formUrl, $(form).serialize())
            .done(function(html) {
                $target.html(html);
                that.$parentContainer.unblock();
            })
    }

    verifyOrgSelected() {
        if (!$(this.checkboxTargets).is(':checked')) {
            alert(`You must select at least one ${this.orgType}`);

            return false;
        } else {
            return true;
        }
    }

    modalMessage() {
        let orgType = this.orgType;

        if (this.noOfOrganizationsSelected > 1) {
            orgType = orgType+"s"
        }

        return `Editing ${this.noOfOrganizationsSelected} ${orgType}`
    }

    selectedOrganizationIds() {
        return this.organizationsSelected.map(o => o['value'])
    }
}
