import { Controller } from "stimulus"

export default class extends Controller {
    static targets = ['form', 'formContainer'];

    save(event) {

        let that = this;
        let path = "/component_indicator_options";
        let method;

        if ($(event.currentTarget).is(':checkbox')) {

           if (this.id && this.id.length) {
               method = "DELETE";
               path = path+`/${this.id}`;
           } else {
               method = "POST";
           }

        } else {

            if (this.id && this.id.length) {
                method = "PATCH";
                path = path+`/${this.id}`;
            } else {
                method = "POST";
            }

        }

        $.ajax({
            type: method,
            url: path,
            data: this.$form.serialize(),
            success: function (data) {
                if (data) {
                    that.id = data.id;

                    if (data.form) {
                        that.$formContainer.html(data.form);
                    }

                }
            },
            error: function (data) {
                alert('Your update failed.  Please refresh the page and try again.')
            },
        });

    }

    get $form() {
        return $(this.formTarget);
    }

    get $formContainer() {
        return $(this.formContainerTarget);
    }

    get id() {
        return this.data.get('id');
    }

    set id(id) {
        return this.data.set('id', id);
    }
}