import {Controller} from "stimulus"

// used in assigning quality indicators and plan evaluation questions
export default class extends Controller {
    static targets = ['schoolDataTable', 'districtDataTable', 'indicatorDataTable', 'dataTable', 'indicatorCheckbox', 'districtCheckbox', 'schoolCheckbox', 'selectedIndicators', 'selectedOrganizations', 'formIndicatorIds', 'formOrganizationIds', 'assignedDataTable', 'assign', 'indicatorCheckAll', 'districtCheckAll', 'schoolCheckAll'];

    initialize(event) {
        const that = this;

        this.initializeTables(event);

        //  Could not get the events handled using the targets :(
        $(document).on( 'draw.dt', '.indicator-data-table, .district-data-table', function () {
            resetCheckAll($(this).data('type'), that.tableMapping);
        } );
    }

    initializeSchoolTable(event) {
        const that = this;

        this.schoolDataTable = $(this.schoolDataTableTarget).DataTable({ columnDefs: [ { targets: 0, orderable: false } ], destroy: true});

        this.tableMapping['school'] = {
            'checkboxes': this.schoolCheckboxTargets,
            'dataTable': this.schoolDataTable,
            'selectedTable': this.selectOrganizationsTable,
            'checkAll': this.schoolCheckAllTarget
        }

        $(document).on( 'draw.dt', '.school-data-table', function () {
            resetCheckAll($(this).data('type'), that.tableMapping);
        } );
    }

    initializeTables(event) {
        if (this.assignedDataTableTargets.length) {

            $(this.assignedDataTableTargets).DataTable();

        } else {

            this.indicatorDataTable = $(this.indicatorDataTableTarget).DataTable({ columnDefs: [ { targets: [1], visible: false }, { targets: 0, orderable: false } ]});
            this.districtDataTable = $(this.districtDataTableTarget).DataTable({ columnDefs: [ {targets: 0, orderable: false} ], destroy: true });
            this.selectOrganizationsTable = $(this.selectedOrganizationsTarget).DataTable({ columnDefs: [ { targets: [0], visible: false } ]});
            this.selectIndicatorsTable = $(this.selectedIndicatorsTarget).DataTable({ columnDefs: [ { targets: [0], visible: false } ]});


            this.tableMapping = {
                'indicator': {
                    'checkboxes': this.indicatorCheckboxTargets,
                    'dataTable': this.indicatorDataTable,
                    'selectedTable': this.selectIndicatorsTable,
                    'checkAll': this.indicatorCheckAllTarget
                },
                'district': {
                    'checkboxes': this.districtCheckboxTargets,
                    'dataTable': this.districtDataTable,
                    'selectedTable': this.selectOrganizationsTable,
                    'checkAll': this.districtCheckAllTarget
                }
            }
        }

    }

    toggleIndicatorCheckboxes(event) {
        toggleCheckboxes(event.target, 'indicator', this.tableMapping)
    }

    toggleDistrictCheckboxes(event) {
        toggleCheckboxes(event.target, 'district', this.tableMapping)
    }

    toggleSchoolCheckboxes(event) {
        toggleCheckboxes(event.target, 'school', this.tableMapping)
    }

    indicatorToggle(event) {
        if (event.target.checked) {
            addSingleRowTo(this.selectIndicatorsTable, event.target, 'indicator')
        } else {
            removeSingleRowFrom(this.selectIndicatorsTable, event.target)
        }

    }

    organizationToggle(event) {
        if (event.target.checked) {
            addSingleRowTo(this.selectOrganizationsTable, event.target, $(event.target).data('type'))
        } else {
            removeSingleRowFrom(this.selectOrganizationsTable, event.target);
        }

    }

    removeSelected(event) {
        const type = $(event.target).data('type');
        const selectTable = this.tableMapping[type]['selectedTable']

        removeSingleRowFrom(selectTable, event.target)
    }

    assign(event) {
        const indicatorIds = this.selectIndicatorsTable.columns([0]).data()[0];
        const organizationIds = this.selectOrganizationsTable.columns([0]).data()[0];

        if (indicatorIds.length && organizationIds.length) {
            $(this.formIndicatorIdsTarget).val(indicatorIds.join());
            $(this.formOrganizationIdsTarget).val(organizationIds.join());

        } else {
            event.preventDefault();

            alert("You must select at least one indicator and one organization.");
        }

    }

}
//  Private methods

function addSingleRowTo(target, selectEl, type) {
    addRowTo(target, selectEl, type)
    target.draw();
}

function addRowTo(target, selectEl, type) {
    const name = $(selectEl).data('name');
    const id   = $(selectEl).data('id');

    const $tr = $(`<tr id="item-${id}" data-id="${id}">`)

    $tr.append($(`<td>${id}</td><td><a href="javascript: void(0)" data-action="bulk-assign-indicator#removeSelected"><span class="icon-remove fa fa-times-circle" data-id="${id}" data-type="${type}"></span></a>${truncate(name, 40)}</td>`))

    target
        .rows
        .add([$tr[0]])

    $(selectEl).parents("tr").addClass('selected')
}

function removeSingleRowFrom(target, selectEl) {
    const id   = $(selectEl).data('id');
    const type = $(selectEl).data('type');
    const checkboxId = `#${type}-${id}`;

    target
        .row( $(`#item-${id}`) )
        .remove()
        .draw();

    $(checkboxId).prop("checked", false);
    $(checkboxId).parents('tr').removeClass('selected')
}

function toggleCheckboxes(checkboxEl, type, tableMapping) {
    const selectedTable = tableMapping[type]['selectedTable'];
    const visibleCheckboxes = visibleCheckboxesFor(type, tableMapping)

    $(visibleCheckboxes).prop("checked", $(checkboxEl).prop('checked'));

    if (checkboxEl.checked) {

        visibleCheckboxes.forEach(function(checkboxEl, index) {
            const itemId = $(checkboxEl).data('id');

            if (selectedTable.row( $(`#item-${itemId}`) ).data() == undefined) {
                addRowTo(selectedTable, checkboxEl, type)
            }
        })

        selectedTable.draw();

        $(visibleCheckboxes).parents('tr').addClass('selected')

    } else {

        visibleCheckboxes.forEach(function(checkboxEl, index) {
            const itemId = $(checkboxEl).data('id');

            selectedTable
                .row( $(`#item-${itemId}`) )
                .remove()
        })

        selectedTable.draw();
        $(visibleCheckboxes).parents('tr').removeClass('selected')
    }

}

function resetCheckAll(type, tableMapping) {
    const visibleCheckboxes = visibleCheckboxesFor(type, tableMapping);
    const totalVisibleRows = totalVisibleRowsInDataTable(type, tableMapping)
    const visibleCheckboxesChecked = visibleCheckboxes.filter(checkbox=>$(checkbox).is(':checked')).length

    if (tableMapping[type]['checkAll'].checked && visibleCheckboxesChecked !== totalVisibleRows) {
        tableMapping[type]['checkAll'].checked = false;
    }
}

function visibleCheckboxesFor(type, tableMapping) {
    const checkboxes = tableMapping[type]['checkboxes'];

    return checkboxes.filter(checkbox=>$(checkbox).is(':visible'))
}

function totalVisibleRowsInDataTable(type, tableMapping) {
    return tableMapping[type].dataTable.page.info().recordsDisplay;
}

function truncate(str, n){
    return (str.length > n) ? str.substr(0, n-1) + '&hellip;' : str;
}