import { Controller } from "stimulus"

export default class extends Controller {
    static targets = [ "source", "target" ]

    load(event) {
        event.preventDefault();

        const $selectBox = $(this.targetTarget);

        $selectBox.empty();

        fetch(this.url)
            .then(response => response.json())
            .then(data => {
                $.each(data, function(i, value) {
                    $selectBox
                        .append($("<option></option>")
                            .attr("value", value.id)
                            .text(value.name));
                });

                $selectBox[0].dispatchEvent(new Event('chosen:updated'));
            })
    }

    get url() {
        const sourceId = $(this.sourceTarget).val();

        let targetAction = new URL(this.data.get("url"), window.location.origin);
        targetAction.searchParams.append(this.sourceName,sourceId);
        return targetAction.toString();
    }

    get sourceName() {
        return this.data.get("source-name");
    }

}