import InstrumentController from "./instrument_controller"

export default class extends InstrumentController {

    loadDetail(event) {

        // TODO figure out why show.bs.collapse is not firing...this is the workaroud
        if ($(event.target).attr('aria-expanded') == "false") {

            const itemId = $(event.target).data('itemId');
            const url = `${this.url}&org_type=${this.orgType}&data_table_detail=${this.template}&item_id=${itemId}`;
            const $target = $(this.contentTarget);

            $target.block(blockUIOptions());

            $target.load(url, () => { $target.unblock() })

        }

    }
}