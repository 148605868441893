import {Controller} from "stimulus"

export default class extends Controller {
    static targets = [ "actions", "target", "stateGoal", "reset", "goalFormat" ]

    load(event) {
        event.preventDefault();

        const goalFormatId = $(event.target).val();
        const url = `/plan_dashboard/${this.planId}/goals/worksheet?goal_format_id=${goalFormatId}`
        const that = this;

        fetch(url)
            .then(response => response.text())
            .then(function (html) {
                $(that.targetTarget).html(html);
                $(that.actionsTarget).show();
                $(event.target).attr("disabled", "disabled")
                $(that.stateGoalTarget).attr("disabled", "disabled")
                $(that.resetTarget).show();
                initializeTinyMCE();
                initializePopover();
//                add pushstate here
            })
    }

    clear(event) {
        $(this.targetTarget).empty();
    }

    save(event) {
        this.enableGoalFields();
    }

    reset(event) {
        $(this.stateGoalTarget).val("")
        $(this.goalFormatTarget).empty();
        this.clear(event);
        this.enableGoalFields();
        $(this.actionsTarget).hide();
    }

    enableGoalFields() {
        $(this.goalFormatTarget).removeAttr("disabled")
        $(this.stateGoalTarget).removeAttr("disabled")
    }

    get planId() {
        return this.data.get("planId");
    }

}