import { Controller } from "stimulus"

export default class extends Controller {
    static targets = ['publishForm', 'container'];

    publish(event) {

        event.preventDefault();

        let that = this;

        $.ajax({
            type: this.$publishForm.attr('method'),
            url: this.$publishForm.attr('action'),
            data: this.$publishForm.serialize(),
            success: function (data) {
                if (data) {
                    that.$container.html(data);
                }
            },
            error: function (data) {
                alert('Your update failed.  Please refresh the page and try again.')
            },
        });

    }

    toggleView(event) {

        event.preventDefault();

        this.$container.load(event.target.href);
    }

    get $publishForm() {
        return $(this.publishFormTarget);
    }

    get $container() {
        return $(this.containerTarget);
    }
}