// app/javascript/controllers/filter_content_controller.js
import {Controller} from "stimulus"

export default class extends Controller {
  static targets = [ "form" , "results" ]

  get url() {
    const formData    = new FormData(this.formTarget)

    const formAction  = new URL(this.formTarget.action);  // copy form target with possible local params
    const existingSearchParams = formAction.searchParams; // shortcut reference to params

    // Append the new form parameters to the target parameters
    for (const [key, value] of formData) {
      existingSearchParams.append(key, value);
    }
    // Reassemble the modified URL
    return formAction.href;
  }

  search(event) {
    event.preventDefault()

    fetch(this.url)
      .then(response => response.text())
      .then(html => this.resultsTarget.innerHTML = html)
  }

}
