import { Controller } from "stimulus"

export default class extends Controller {

    static targets = ['summary', 'includeDueDate', 'includeReviewer', 'reviewers', 'dueDate', 'form', 'organizations', 'updateBtn'];

    show(event) {

        this.orgByTypeController = event.detail.orgByTypeController;

        this.resetModal();

        this.updateSummary(event.detail.modalMessage);
        this.updateOrganizationIds(event.detail.organizationIds);

        bootstrapHack.showModal(this.element)
    }

    update(event) {
        event.preventDefault();

        if (this.verifyElementSelected()) {
            this.postChanges();
        } else {
            alert("You didn't check anything to update!");
        }

    }

//    private methods

    updateSummary(message) {
        $(this.summaryTarget).text(message)
    }

    updateOrganizationIds(organizationIds) {
        $(this.organizationsTarget).val(organizationIds.join(','))
    }

    resetModal() {
        $(this.updateBtnTarget).prop('disabled', false);
        $(this.updateBtnTarget).val('Update');

        this.formTarget.reset();
        $('.modal-body select')[0].dispatchEvent(new Event('chosen:updated'));
    }

    verifyElementSelected() {
        return ($(this.includeDueDateTarget).is(':checked') || $(this.includeReviewerTarget).is(':checked'))
    }

    postChanges() {
        const formUrl = $(this.formTarget).attr('action');
        const that = this;

        $(this.updateBtnTarget).prop('disabled', true);
        $(this.updateBtnTarget).val('Saving...');

        $.post(formUrl, $(this.formTarget).serialize())
            .done(function(html) {
                const modalEvent = new CustomEvent('bulkUpdate', { 'detail': { orgTableHTML: html } });

                that.orgByTypeController.element.dispatchEvent(modalEvent);

            })
            .always(function() {
                bootstrapHack.hideModal(that.element);
            })
    }
}